<template>
  <template v-if="current?.layout === 'blank'">
    <BreakpointVisualizer />
    <div class="container mx-auto flex min-h-dvh flex-col justify-center p-8">
      <slot></slot>
    </div>
  </template>
  <template v-else>
    <BreakpointVisualizer />
    <SiteHeader />
    <main class="min-h-dvh pb-20">
      <slot></slot>
    </main>
    <SiteFooter />
  </template>
</template>

<script lang="ts" setup>
  import BreakpointVisualizer from '~/components/BreakpointVisualizer.vue'

  const { current } = Waypoint.use()
</script>

<style scoped></style>

<style></style>
