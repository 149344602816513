<template>
  <small class="block max-w-[50ch] text-base font-light text-white">
    Reminder:
    <span v-if="isDaysLeft">
      <span class="highlight"> {{ numDaysleft }} {{ daysText }} </span>
      until the Annual Enrollment Period is over.
    </span>

    <span v-if="isLastDay">
      <span class="highlight">It's the last day</span> of the Annual Enrollment Period.
    </span>
    Let us help you enroll in a plan today.
  </small>
</template>

<script lang="ts" setup>
  import dayjs from 'dayjs'

  const currentYear = dayjs().year()
  const currentDate = ref(dayjs())
  const endOfAep = dayjs(`${currentYear}-12-07T23:59:59`)
  const numDaysleft = ref(0)
  const isDaysLeft = computed(() => numDaysleft.value > 0)
  const isLastDay = computed(() => numDaysleft.value === 0)
  const daysText = computed(() => (numDaysleft.value === 1 ? 'day' : 'days'))

  const updateTimer = () => {
    const diff = endOfAep.diff(currentDate.value, 'second')
    numDaysleft.value = Math.floor(diff / (24 * 60 * 60))
  }

  const updateCurrentDate = () => {
    currentDate.value = dayjs()
  }

  onMounted(() => {
    updateTimer()
    setInterval(updateTimer, 1000)

    updateCurrentDate()
    setInterval(updateCurrentDate, 1000)
  })

  onUnmounted(() => {
    clearInterval(updateTimer)
    clearInterval(updateCurrentDate)
  })
</script>

<style scoped>
  .highlight {
    @apply border-b-2 border-[#ffffff50] text-lg font-semibold;
  }
</style>
