<template>
  <p>
    Currently getting help from one of our world class support associates?
    <HpAnchor id="cobrowse-start" @click="launch()">Click here</HpAnchor> so they can see your
    screen.
  </p>
</template>

<script lang="ts" setup>
  const { $root } = useNuxtApp()

  const emit = defineEmits(['launch-auvious'])

  function launch() {
    $root.emit('launch-auvious')
    emit('launch-auvious')
  }
</script>

<style scoped></style>
