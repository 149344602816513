<template>
  <div v-if="enabled" class="visualizer">
    <span class="text-primary">{{ width }}px</span>
    <TransitionPop appear mode="out-in" :enter="{ duration: 0.2 }" :leave="{ duration: 0.2 }">
      <span class="text-danger font-bold" :key="currentBreakpoint">{{ currentBreakpoint }}</span>
    </TransitionPop>
    <span class="text-center font-bold">{{ device }}</span>
  </div>
</template>

<script setup lang="ts">
  import { useWindowSize } from '@vueuse/core'
  import { Breakpoints } from '#imports'

  const env = useRuntimeConfig()

  const enabled = computed(() => !!env.public.showBreakpointVisualizer)

  const { width } = useWindowSize()
  const { breakpoints } = Breakpoints.use()

  const currentBreakpoint = computed(() => breakpoints.active().value ?? 'xs')

  const { isDesktop, isTablet, isMobile } = Breakpoints.use()

  const device = computed(() => {
    switch (true) {
      case isDesktop.value:
        return 'desktop'
      case isTablet.value:
        return 'tablet'
      case isMobile.value:
        return 'mobile'
    }
  })
</script>

<style scoped lang="postcss">
  .visualizer {
    @apply fixed left-[5] top-[3.8rem] z-[100] flex w-fit flex-col flex-col bg-gray-800/20 p-1 text-center text-sm md:top-[4.2rem];

    span {
      @apply !leading-tight;
    }
  }
</style>
