<template>
  <header class="sticky top-0 z-40 bg-white px-5 py-2" @keydown.esc="closeDropdown">
    <nav class="mx-auto flex w-full max-w-[--pageWidth] items-center justify-between">
      <!-- Mobile back button -->
      <HpButton
        variant="minimal"
        icon="back"
        icon-classes="w-8 text-primary-600"
        icon-location="left"
        class="block w-4 md:hidden"
        :class="{ invisible: isStartPage }"
        @click="back"
      >
        <span class="sr-only">Go back</span>
      </HpButton>

      <div class="w-full md:w-fit">
        <HpAnchor
          @click.prevent="() => maybeDo(() => push('/'))"
          class="mx-auto flex w-fit justify-center"
        >
          <LogoSvg class="hidden sm:flex sm:w-[11.5rem]" />
          <MobileLogoSvg class="w-12 sm:hidden" />
        </HpAnchor>
      </div>

      <HpAnchor
        href="tel:1-855-922-5051"
        class="hidden whitespace-nowrap text-gray-900 no-underline md:flex md:flex-col md:items-center"
      >
        <span>
          <span class="mr-1 font-semibold text-primary">(855) 922-5051</span>
          | TTY 711
        </span>
        <span class="text-sm font-bold text-gray-800">Monday - Friday | 9am - 6pm, Central</span>
      </HpAnchor>

      <div class="relative">
        <HpButton
          variant="secondary"
          :icon="headerCTA.icon"
          icon-classes="w-6"
          icon-location="left"
          class="hidden w-fit min-w-[14rem] md:block"
          @click.prevent="headerCTA.action"
        >
          {{ headerCTA.label }}
        </HpButton>

        <!-- Desktop Dropdown Menu -->
        <ul
          ref="dropdownMenu"
          class="absolute left-1/2 top-full w-11/12 origin-top -translate-x-1/2 scale-y-0 overflow-hidden rounded-b-tooltip bg-white shadow-menu transition-transform"
          :class="{ grow: isDropdownOpen, hidden: !isDropdownOpen }"
        >
          <li class="border-b border-border px-4 pb-2.5 pt-3.5 hover:bg-gray-200">
            <NuxtLink
              to="/dashboard"
              @click="closeDropdown"
              class="flex items-center gap-1 text-base font-semibold text-primary-900 no-underline"
            >
              <HpIcon name="dashboard" class="w-5" />
              Your Dashboard
            </NuxtLink>
          </li>
          <li class="px-4 py-1 hover:bg-gray-200">
            <HpButton
              variant="minimal"
              class="flex w-fit items-center !text-base !font-semibold !text-primary-900"
              @click.prevent="signOut"
              @click="closeDropdown"
            >
              <HpIcon name="logout" class="w-5" />
              Sign Out
            </HpButton>
          </li>
        </ul>
      </div>

      <div class="relative md:hidden">
        <HpButton variant="minimal" @click="show('talkdesk')" class="absolute -top-1 right-14">
          <HpIcon name="help" class="scale-150 text-primary-600" />
          <span class="sr-only">Get Help</span>
        </HpButton>
        <SiteNavHamburger :is-menu-open="isMenuOpen" @click="toggleMenu" @keydown.esc="closeMenu" />
      </div>
    </nav>
  </header>

  <!-- Mobile Menu -->
  <client-only>
    <SiteNav :is-menu-open="isMenuOpen" @close-menu="closeMenu" />
  </client-only>

  <!-- Page backdrop for open mobile menu -->
  <div
    v-if="isMenuOpen"
    @click="closeMenu"
    class="fixed inset-0 z-40 grid place-content-center md:hidden"
  ></div>
</template>

<script lang="ts" setup>
  import LogoSvg from '~/assets/images/healthpilot.svg?component'
  import MobileLogoSvg from '~/assets/images/healthpilot-icon.svg?component'
  import { onClickOutside } from '@vueuse/core'

  const { show } = ChatController.use()

  const { isAuthenticated, signOut, signUpRedirect, signInRedirect } = Auth.use()
  const isSignedIn = ref(isAuthenticated.value)
  watch(isAuthenticated, (val) => (isSignedIn.value = val))

  const { isStartPage } = AppRoute.use()

  const { back, push } = AppRouter.use()

  const { maybeDo } = ActionGuard.use()

  const headerCTA = computed(() => {
    switch (true) {
      case isSignedIn.value:
        return {
          label: 'Your Account',
          icon: 'account',
          action: () => toggleDropdown()
        }
      case isStartPage.value && !isSignedIn.value:
        return {
          label: 'Sign in',
          icon: 'account',
          action: () => signInRedirect()
        }
      default:
        return {
          label: 'Save your progress',
          icon: 'touch',
          action: () => maybeDo(() => signUpRedirect())
        }
    }
  })

  const isMenuOpen = ref(false)

  const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value
  }

  const closeMenu = () => {
    isMenuOpen.value = false
  }

  const isDropdownOpen = ref(false)

  const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value
  }

  const closeDropdown = () => {
    isDropdownOpen.value = false
  }

  watch(isMenuOpen, () => {
    isMenuOpen.value
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto')
  })

  const dropdownMenu = ref(null)

  onClickOutside(dropdownMenu, () => closeDropdown())
</script>

<style scoped>
  .grow {
    @apply scale-y-100;
  }
</style>
