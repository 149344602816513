<template>
  <footer
    class="xs:py-20 flex flex-col items-center space-y-6 border-t border-gray-500 bg-white px-5 py-14"
  >
    <div class="flex space-x-8">
      <BBBSvg />
      <TrustpilotSvg />
    </div>

    <p
      class="footer-disclaimer text-primary-900 max-w-[--pageWidthSm] text-base leading-relaxed"
      id="footer-pre"
    ></p>

    <p
      class="text-primary-900 max-w-[--pageWidthSm] text-base leading-relaxed"
      v-html="formattedDisclaimer"
    />

    <div class="text-base" id="filing-code">
      <p v-if="!!(SiteFilingCode.supplementaryFilingCode.value.length > 0)">
        {{ SiteFilingCode.supplementaryFilingCode.value }}
      </p>
      <p>{{ SiteFilingCode.filingCode.value }}</p>
    </div>

    <div class="border-border rounded-2xl border px-4 py-2 text-sm" v-if="isQaOrDevelopment">
      Journey: {{ session?.flags['journey-id'] }}
      <br />
      Profile Id: {{ session.profileId }}
    </div>

    <nav aria-label="Footer" class="flex flex-col items-center">
      <div
        class="border-border flex w-full flex-wrap items-center justify-center gap-4 border-t py-8 sm:gap-10"
      >
        <HpAnchor
          :href="content.ext.href_privacy"
          target="_blank"
          class="text-primary-900 font-bold no-underline"
        >
          Privacy Policy
        </HpAnchor>
        <HpAnchor
          :href="content.ext.href_terms"
          target="_blank"
          class="text-primary-900 font-bold no-underline"
        >
          Terms of Use
        </HpAnchor>
        <HpAnchor
          href="#"
          v-dialog-target:site-disclosures-modal
          class="text-primary-900 whitespace-nowrap font-bold no-underline"
        >
          Additional disclaimers
        </HpAnchor>
      </div>
      <client-only>
        <AuviousLauncher class="mb-8 w-2/3 md:mb-2" />
      </client-only>

      <SiteDisclosuresModal id="site-disclosures-modal" />
    </nav>
  </footer>
</template>

<script lang="ts" setup>
  import BBBSvg from 'assets/images/bbb.svg'
  import TrustpilotSvg from 'assets/images/trustpilot.svg'
  import { PlanType } from '~/generated/api-clients-generated'
  import { SessionStore } from '~/stores/session'

  const { getComponentContent } = Cms.use()
  const { content } = await getComponentContent('SiteFooter')

  const session = SessionStore.use()

  const validPlanTypes = [PlanType.MAPD, PlanType.PDP]

  const noOfCarriers = computed(
    () => _uniq(session.carrierFootprint.map((x) => x.carrierKey)).length
  )

  const noOfPlans = computed(() =>
    session.carrierFootprint.reduce(
      (acc, n) => acc + n.planTypes?.reduce((acc0, n0) => acc0 + n0!.count!, 0)!,
      0
    )
  )

  // if we don't have footprint yet, don't show "disclaimer_counts" sentence
  const formattedDisclaimer = computed(() =>
    content.value?.ext?.disclaimer?.replace(
      '{{disclaimer_counts}}',
      session.carrierFootprint.length
        ? content.value?.ext?.disclaimer_counts
            ?.replace('{{carrier_count}}', noOfCarriers.value?.toString())
            .replace('{{plan_count}}', noOfPlans.value?.toString())
        : ' '
    )
  )

  nextTick(() => {
    if (!SiteFilingCode.filingCode.value.length) SiteFilingCode.setFilingCode()
  })

  const { isQaOrDevelopment } = DevModeHelpers.use()
</script>

<style scoped>
  :deep(a) {
    @apply text-base;
  }

  :deep(svg) {
    @apply w-auto;
  }
</style>
