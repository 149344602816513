<template>
  <ul class="flex flex-col space-y-12 break-words">
    <li
      v-for="(disclosure, idx) in displayedDisclosures"
      class="space-y-5 border-b border-gray-500 pb-12"
    >
      <h2 class="text-3xl">
        {{ disclosure?.brandName }}
        <small class="block text-lg font-normal text-gray-800">Plan Disclosures</small>
      </h2>
      <div v-html="disclosure.body" class="wysiwyg-content"></div>
    </li>
  </ul>

  <section class="general-disclosures">
    <h2 class="heading mb-5">General Disclosures</h2>
    <div v-html="healthpilotDisclosures?.body" class="wysiwyg-content"></div>
  </section>
</template>

<script lang="ts" setup>
  import { SessionStore } from '~/stores/session'
  import { EngineStore } from '~/stores/engine'
  import type { DisclosureContent } from '~/types/cms'

  const session = SessionStore.use()
  const engine = EngineStore.use()

  const keys = computed(() => {
    return session.carrierFootprint.flatMap((x) => x.disclosures!)
  })

  const active = computed(() => {
    return engine.displayedQuotes.map((x) => x.details.disclosuresKey)
  })

  const displayedDisclosures = computed(() => {
    return carrierDisclosures.value.filter((x) => active.value.includes(x.key))
  })

  const { getCarrierDisclosureContent } = Cms.use()

  const healthpilotDisclosures = ref<DisclosureContent | null>(null)
  const carrierDisclosures = ref<Array<DisclosureContent>>([])

  getCarrierDisclosureContent(session.planYear!, ['healthpilot']).then(({ disclosures }) => {
    healthpilotDisclosures.value = disclosures.value[0] ?? null
  })

  watchEffect(() => {
    console.log('WATCH DISCLOSURES')
    getCarrierDisclosureContent(session.planYear!, keys.value).then(({ disclosures }) => {
      carrierDisclosures.value = disclosures.value!
    })
  })
</script>

<style scoped>
  .wysiwyg-content {
    @apply flex flex-col;
  }

  .wysiwyg-content :deep(p) {
    @apply pb-4 text-left text-base leading-[1.75rem] text-gray-900;
  }

  .general-disclosures {
    @apply mt-12;
  }

  .general-disclosures :deep(ol) {
    @apply mb-5 ml-8 grid list-disc gap-5 text-left text-gray-900;
  }
</style>
